export const dataText = {
  header: "Tina",
  smallHeader: "Аппарат для перманентного макияжа",
  smallTitle: "Теневые стрелки за 40 минут",

  describes: {
    title: "Характеристики аппарата:",
    1: "Коллекторный двигатель 5 Ватт",
    2: "Рабочий диапазон 5-10 Вольт",
    3: "Максимальный ток двигателя 0.5 Ампер",
    4: "Скорость двигателя 4.500- 9.000 об/мин",
    5: "Количество проколов в секунду 60-130",
    6: "Ход эксцентрика 3.5 мм",
    7: "Вылет иглы 0-4.5 мм",
    8: "Подключение RCA",
    9: "Вес машинки 108 грамм",
  },
  equipment: {
    title: "Комплектность:",
    1: "Аппарат для татуировки и перманентного макияжа ТN-1 - 1 шт",
    2: "Клип-корд - 1 шт.",
    3: "Чехол 1 шт.",
    4: "Гарантийный талон и инструкция по эксплуатации - 1 шт.",
  },
};
