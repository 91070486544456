import React from "react";

export const Payment = () => {
  window.scrollTo(0, 0);
  const list = [
    "интернет-банкинге",
    "мобильном банкинге",
    "инфокиоске",
    "банкомате",
    "кассе и т.д.",
  ];
  const listItem = [
    "наличных денежных средств",
    "любых электронных денег",
    "банковских платежных карточек",
  ];
  const way = [
    "Пункт “Система “Расчет” (ЕРИП) ",
    "Интернет-магазины/сервисы",
    "A-Z Латинские домены ",
    "tina.of.by",
    "Для оплаты «Товара»/ «Услуги» ввести Номер заказа",
    "Ввести сумму платежа (если не указана)",
    "Проверить корректность информации",
    "Совершить платеж.",
  ];
  return (
    <div className="bg-dark-100 text-base md:text-4xl  text-left p-1 md:p-12">
      <div>
        <h1 className="text-2xl p-4 sm:text-3xl md:text-4xl xl:text-5xl text-center md:p-8">
          Условия оплаты
        </h1>
        <h2 className="text-2xl p-4 sm:text-3xl md:text-4xl text-center md:p-8">
          Для покупки товара на главной странице нажмите "Купить"
        </h2>
        <h3 className=" text-2xl  m-2 text-center md:text-4xl overflow-hidden">
          Способы покупки товара:
        </h3>
        <h4 className=" text-2xl  m-2 text-center md:text-4xl overflow-hidden">
          Банковской платёжной картой через систему WebPay:
        </h4>
        <p className="p-2 md:p-8">
          "Безопасный сервер <a href={"https://webpay.by/"}>WEBPAY </a>{" "}
          устанавливает шифрованное соединение по защищенному протоколу TLS и
          конфиденциально принимает от клиента данные его платёжной карты (номер
          карты, имя держателя, дату окончания действия, и контрольный номер
          банковской карточке CVC/CVC2). После совершения оплаты с
          использованием банковской карты необходимо сохранять полученные
          карт-чеки (подтверждения об оплате) для сверки с выпиской из
          карт-счёта (с целью подтверждения совершённых операций в случае
          возникновения спорных ситуаций). В случае, если Вы не получили заказ
          (не оказана услуга), Вам необходимо обратиться (в службу технической
          поддержки) по телефону: +375333792389 или e-mail
          Shendrikova1964@mail.ru. При оплате банковской платежной картой
          возврат денежных средств осуществляется на карточку, с которой была
          произведена оплата".
        </p>
      </div>
      <div className={"text-left"}>
        <h4 className=" m-2 text-center text-2xl md:text-4xl  overflow-hidden">
          {" "}
          Система расчета ЕРИП:{" "}
        </h4>
        <p className="p-2 md:p-8">
          {" "}
          Оплату Вы можете произвести в любом банке:
        </p>
        <ul>
          {list.map((item) => (
            <li className="py-1">{item}</li>
          ))}
        </ul>

        <p className="p-2 md:p-8"> Совершить оплату можно с использованием:</p>
        <ul>
          {listItem.map((item) => (
            <li className="py-1">{item}</li>
          ))}
        </ul>

        <p className="p-2 md:p-8">Для проведения платежа необходимо выбрать:</p>
        <ul>
          {way.map((item) => (
            <li className="py-1">{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};
