import React, { FC } from "react";
import css from "./BlockCourse.module.css";
import makeup from "../../../assets/img/makeup.jpg";
import makeup1 from "../../../assets/img/makeup1.jpg";
import makeup2 from "../../../assets/img/makeup2.jpg";
import tinaMaster from "../../../assets/img/tina_master.jpg";
import { listDescriptionCorse } from "../../molecules/list/mockData";
import { PurchaseBtn } from "../../molecules/PurchaseBtn/PurchaseBtn";
import { dataText } from "../../../translate/ru/dataRu";

interface PropsBlockCourse {}

export const BlockCourse: FC<PropsBlockCourse> = () => {
  return (
    <>
      <h2
        className={`${css.title} text-xl p-3 sm:text-4xl md:text-5xl text-center md:p-16`}
      >
        {dataText.smallTitle}
      </h2>

      <div className={css.blockCorse}>
        <div className={css.blockInfo}>
          <div className={css.blockMaster}>
            <div className={css.wrapperImgMaster}>
              <img src={tinaMaster} alt="master" className={css.imgMaster} />
              <img src={makeup} alt="makeup" className={css.makeup3} />
              <img src={makeup2} alt="makeup" className={css.makeup1} />
              <img src={makeup1} alt="makeup" className={css.makeup2} />
            </div>
            <ul className={css.listCourse}>
              <p className="overflow-hidden text-base m-1.5 sm:text-2xl 2xl:text-3xl md:m-4">
                Погрузитесь в мир красоты с нашими уникальными курсами по
                макияжу! Научитесь создавать идеальные стрелки и осваивайте
                технику, которая подчеркнет вашу индивидуальность. В рамках
                курсов вы получите доступ к палитре цветов, где черный — это не
                единственный выбор, а лишь начало вашего творческого пути. Также
                мы предлагаем мастер-класс по анатомии лица, который поможет вам
                рисовать эскизы с легкостью и уверенностью. Позвольте себе стать
                художником своей красоты!
              </p>
              <hr />
              {listDescriptionCorse.map((i) => (
                <li
                  key={i.id}
                  className="overflow-hidden text-base m-1.5 sm:text-2xl 2xl:text-3xl md:m-4"
                >
                  {i.title}
                </li>
              ))}

              <p
                className={
                  " text-xl p-4 sm:text-3xl md:text-4xl text-center md:p-12 lg:py-8 "
                }
              >
                Стоимость: 98 BYN
              </p>
              <PurchaseBtn href={"https://w-p.by/hTAh"} />
            </ul>
          </div>
        </div>

        <div className={css.blockImg}></div>
      </div>
    </>
  );
};
