import React from "react";
import { Footer } from "./components/organisms/footer/Footer";
import { Routes, Route } from "react-router-dom";
import { ReturnGoods } from "./components/organisms/footer/returnGoods/returnGoods";
import { MainPage } from "./components/organisms/mainPage/mainePage";
import { Declaration } from "./components/molecules/declaration/declaration";
import { Delivery } from "./components/organisms/footer/delivery/delivery";
import { Cheque } from "./components/molecules/cheque/cheque";
import { Payment } from "./components/molecules/payment/payment";

export const App = () => {
  return (
    <>
      <Routes>
        <Route path="" element={<MainPage />} />
        <Route path={"/returnGoods"} element={<ReturnGoods />} />
        <Route path={"/declaration"} element={<Declaration />} />
        <Route path={"/delivery"} element={<Delivery />} />
        <Route path={"/сheque"} element={<Cheque />} />
        <Route path={"/payment"} element={<Payment />} />
      </Routes>
      <Footer />
    </>
  );
};
