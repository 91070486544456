export const Delivery = () => {
  window.scrollTo(0, 0);
  return (
    <div className="bg-dark-100 text-base md:text-2xl text-left p-1 md:p-12">
      <h1 className="uppercase m-10  text-center text-3xl overflow-hidden">
        Условия доставки
      </h1>
      <h2 className="text-2xl p-4 sm:text-3xl md:text-4xl text-center md:p-8">
        Указание параметров (адрес,время) доставки осуществляется по номеру
        телефона +375333792389 (Viber,Telegram)
      </h2>
      <p className="p-2 md:p-8">
        Отправка заказов осуществляется для вас любой удобной службой доставки
        (Сдэк, белпочта, европочта, автолайт). Максимальное время обработки
        заказа до отправки 1 день. Доставка обычно осуществляется 2-4 дня.
        Отправка осуществляется только после полной оплаты товара! Стоимость
        доставки оплачивается при получении согласно тарифам выбранной вами
        службы доставки. Средняя стоимость доставки составляет 5-13 BYN по
        Беларуси. При оформлении заказа с доставкой почтой, обязательно
        указывайте полный почтовый адрес в формате: индекс, город, улица,
        дом\квартира, номер телефона.
      </p>
      <h2 className=" m-2 text-center text-2xl overflow-hidden">
        Экспресс курьер по Беларуси
      </h2>
      <p className="p-2 md:p-8">
        Отправка заказов экспресс-почтой с доставкой до двери по Беларуси.
        Заказы, оформленные до 15:00 отправляются в этот же день. Заказы,
        оформленные после 15:00 отправляются на следующий рабочий день. Доставка
        заказа осуществляется в течении следующего дня с момента отправки
        заказа. Курьер доставит Ваш заказ по указанному Вами адресу при
        оформлении заказа. Отправка осуществляется только после полной отплата
        заказа! Стоимость доставки экспресс курьером по Беларуси оплачивается
        при получении согласно тарифам РУП «Белпочта». Средняя стоимость
        доставки составляет 8-16 BYN.
      </p>
    </div>
  );
};
