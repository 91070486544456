import { FC } from "react";

interface Props {
  href: string;
}

export const PurchaseBtn: FC<Props> = ({ href }) => {
  return (
    <div className={"flex justify-center mb-20 mt-2"}>
      <a href={href} target="_blank" rel="noreferrer">
        <button
          className={
            "py-1 px-4 text-xl p-2 sm:text-3xl md:text-4xl text-center md:p-12 md:py-3 border-2  border-amber-700 rounded-xl backdrop-blur-0   hover:text-green-100"
          }
        >
          Купить
        </button>
      </a>
    </div>
  );
};
