import React from "react";

export const ReturnGoods = () => {
  window.scrollTo(0, 0);
  const list = [
    " с момента покупки прошло не более 7 дней (не считая дня покупки);",
    " товар непродовольственный;",
    " товар надлежащего качества (если товар ненадлежащего качества – см    условия возврата и обмена товара ненадлежащего качества); товар не  был в употреблении, т.е. упаковка не нарушена, нет следов  эксплуатации, комплектация сохранена (в случае потери упаковки, бирок, ярлыков и т.п. возврат или обмен возможен по согласованию с  администратором магазина);",
    " сохранены потребительские свойства;",
    " имеются доказательства приобретения товара у данного продавца:      товарный чек либо иной документ, подтверждающий оплату товара (в  случае потери чека или иного документа необходимо точно установить  дату покупки, и только тогда возврат/обмен возможен по согласованию  с администратором магазина);",
    " товар не входит в перечень товаров, которые не подлежат возврату или обмену;",
    " товар не является технически сложным.",
  ];

  const listExchange = [
    " 1.1. замены недоброкачественного товара товаром надлежащего качества;",
    " 1.2. соразмерного уменьшения покупной цены товара;",
    " 1.3. незамедлительного безвозмездного устранения недостатков товара;",
    " 1.4. возмещения расходов по устранению недостатков товара.",
  ];
  const data = [
    "    2. Потребитель вправе потребовать замены технически сложного товара или дорогостоящего товара в случае обнаружения его существенных  недостатков. Перечень таких технически сложных товаров утверждаетсяПравительством Республики Беларусь.",
    "   3. Вместо предъявления указанных в пунктах 1 и 2 настоящей статьитребований потребитель вправе расторгнуть договор розничнойкупли-продажи и потребовать возврата уплаченной за товар денежнойсуммы.",
    " Существенный недостаток товара – неустранимый недостаток, либонедостаток, который не может быть устранен без несоразмерных расходов(в размере тридцати и более процентов от стоимости товара)либонесоразмерных затрат времени (превышающих максимально допустимыезатраты времени), или выявляется неоднократно, или проявляется вновьпосле его устранения, либо другие подобные недостатки.",
    "   Статья 22. Безвозмездное устранение недостатков товара продавцом 1.Недостатки, обнаруженные в товаре, должны быть устранены продавцом(изготовителем, поставщиком, представителем, ремонтной организацией)безвозмездно и незамедлительно. В случае, если устранить недостаткинезамедлительно не представляется возможным, максимальный срок ихустранения не может превышать четырнадцати дней со дня предъявленияпотребителем требования об устранении недостатков товара.",
    " Статья 23. Замена товара ненадлежащего качества 1. В случаеобнаружения потребителем недостатков товара и предъявления требованияо замене такого товара продавец (изготовитель, поставщик представитель) обязан заменить такой товар незамедлительно, а принеобходимости дополнительной проверки качества такого товара продавцом(изготовителем, поставщиком, представителем) – заменить его в течениечетырнадцати дней со дня предъявления указанного требования.",
    "      Статья 28. Право потребителя на обмен и возврат товара надлежащегокачества 1. Потребитель вправе в течение четырнадцати дней с моментапередачи ему непродовольственного товара, если более длительный срокне объявлен продавцом, в месте приобретения или иных местах,объявленных продавцом, возвратить товар надлежащего качества илиобменять его на аналогичный товар других размера, формы, габарита,фасона, расцветки или комплектации, произведя в случае разницы в цененеобходимый перерасчет с продавцом. 2. Требование потребителя обобмене либо возврате товара подлежит удовлетворению, если товар не былв употреблении, сохранены его потребительские свойства и имеютсядоказательства приобретения его у данного продавца.",
  ];

  const listConditions = [
    "При возникновении гарантийного случая, наличии претензии по качеству или\n" +
      "        комплектности приобретенной продукции просим Вас заполнить форму\n" +
      "        обращения в сервисный отдел, после чего с Вами свяжется администратор.\n" +
      "        Если товар подлежит замене, мы примем его даже при отсутствии чека,\n" +
      "        убедившись, что товар куплен именно у нас.",
    "  Условия обмена и возврата товара надлежащего качества: Условия\n" +
      "        обмена/возврата товара регулируется Законом Республики Беларусь от 9\n" +
      "        января 2002 г. N 90-З «О защите прав потребителей» в редакции от 4\n" +
      "        января 2014 г. № 106-З, где потребителю предоставляется право обменять\n" +
      "        или вернуть товар при одновременном соблюдении следующих условий:",
  ];

  const listCompliance = [
    "   обменять на аналогичный товар у продавца, у которого был приобретен\n" +
      "          товар (на усмотрение администратора магазина оформляется документ\n" +
      "          возврата или обмена по предъявлении удостоверения личности\n" +
      "          покупателя);",
    "    вернуть продавцу, получив за него уплаченные деньги (в случае\n" +
      "          безналичной оплаты покупки возврат производится соответственно).",
  ];

  return (
    <div className="bg-dark-100 text-base md:text-2xl text-left p-1 md:p-12">
      <h1 className="uppercase m-10  text-center text-3xl overflow-hidden">
        Возврат и обмен товара
      </h1>
      {listConditions.map((item) => (
        <p className="p-2 md:p-8">{item}</p>
      ))}

      <ul>
        {list.map((item) => (
          <li className="py-1">{item}</li>
        ))}
      </ul>
      <p className="p-2 md:p-8">
        При соблюдении этих условий приобретенный товар можно:
      </p>
      <ul>
        {listCompliance.map((item) => (
          <li className="py-1">{item}</li>
        ))}
      </ul>
      <h2 className={"text-center"}>Обмен товара</h2>
      <p className="p-2 md:p-8">
        Обмену подлежит лишь поврежденный товар ненадлежащего качества или
        имеющий какой-либо дефект/брак. Ели Вы хотите обменять Вашу покупку на
        точно такую же, вышлите нам письмо с указанием номера заказа и желаемого
        к обмену товара на @ Выдержки из законодательных актов, регламентирующие
        возврат (замену) товара ЗАКОН РЕСПУБЛИКИ БЕЛАРУСЬ от 9 января 2002 г. N
        90-З «О защите прав потребителей» в редакции от 4 января 2014 г. № 106-З
        ГЛАВА 3. ЗАЩИТА ПРАВ ПОТРЕБИТЕЛЯ ПРИ РЕАЛИЗАЦИИ ЕМУ ТОВАРА Статья 20.
        Права потребителя в случае реализации ему товара ненадлежащего качества
      </p>
      <h3 className={"p-2 md:p-8"}>
        1. Потребитель, которому продан товар ненадлежащего качества, если его
        недостатки не были оговорены продавцом, вправе по своему выбору
        потребовать:
      </h3>
      <ul>
        {listExchange.map((item) => (
          <li className="py-1">{item}</li>
        ))}
      </ul>
      {data.map((item) => (
        <p className="p-2 md:p-8">{item}</p>
      ))}
    </div>
  );
};
