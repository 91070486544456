import React from "react";
import "./footer.module.css";
import { Link } from "react-router-dom";
import WebPay from "../../../assets/img/МПС+ЕРИП.svg";

export const Footer = () => {
  return (
    <div className="bg-dark-100 text-center p-8 border-t">
      <div className={"flex justify-center"}>
        <img
          src={WebPay}
          alt={"WebPay"}
          className={" max-h-12  mb-3 opacity-80"}
        />
      </div>
      <div className={"pt-3"}>
        ИП Заяц Валентина Васильевна УНП791093989 Гос.регистрация от 7 сентября
        2018г Номер 0704780
      </div>
      <div className={"pt-3"}>
        Юр.адрес: 212022 Республика Беларусь г.Могилёв пер. Котельникова 17
        +375333792389 e-mail Shendrikova1964@mail.ru
      </div>
      <div className={"pt-3"}>
        Время работы пункта самовывоза: ПН-ПТ с 10:00 до 17:00 | Время приёма
        заказов по телефону: ПН-ПТ с 9:00 до 20:00 Приём заказов на сайте:
        круглосуточно
      </div>
      <p>
        Регистрационный номер в торговом реестре Республики Беларусь: 557597 от
        16 мая 2023
      </p>

      <div
        className={
          "flex flex-col text-center md:flex-row md:justify-center md:gap-5"
        }
      >
        <p className={"text-center"}>
          <Link to={"/returnGoods"}>Возврат и обмен товара</Link>
        </p>
        <p className={"text-center"}>
          <Link to={"/declaration"}>Декларация о соответствии</Link>
        </p>
        <p className={"text-center"}>
          <Link to={"/delivery"}>Условия доставки товара</Link>
        </p>
        <p className={"text-center"}>
          <Link to={"/сheque"}>Образец электронного чека</Link>
        </p>
        <p className={"text-center"}>
          <Link to={"/payment"}>Условия оплаты</Link>
        </p>
      </div>
    </div>
  );
};
